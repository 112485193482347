/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { getEnv } from "../../../../configs";
import blogsData from "../../data/Blogs-2.json";

function Footer() {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    setBlogs(blogsData);
  }, []);

  return (
    <footer className="footer-s1">
      <div className="container">
        {/* <div className="row">
                    <div className="col-12">
                        <div className="footer-cta">
                            <div className="p-left">
                                <span>For IT Company</span>
                                <h2>Join IT Solution Our Community</h2>
                            </div>
                            <div className="p-right">
                                <form action="#">
                                    <div className="f-subs-form">
                                        <input type="text" placeholder="Enter your email address"/>
                                        <button type="submit">Subscribe Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="row f-main">
          <div className="col-xl-5 col-lg-12">
            <div className="f-widget widget-1">
              <div className="logo">
                <a href="/">
                  <img src={"/assets/images/logos/logo-black-2.png"} alt="" />
                </a>
              </div>
              <p className="desc">
                Chúng tôi xây dựng các ứng dụng web và di động chuyên nghiệp,
                thông minh, dễ mở rộng để giúp đơn giản hóa quy trình cho các
                doanh nghiệp.
              </p>
              <ul className="social-icons-s1">
                <li>
                  <a href={getEnv("COMPANY_FACEBOOK")} target="_blank">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                {/* <li>
                                    <a href="https://www.facebook.com/QuomodoSoft"
                                    ><i className="fa-brands fa-twitter"></i
                                    ></a>
                                </li> */}
                <li>
                  <a href={getEnv("COMPANY_LINKEDIN")} target="_blank">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
                {/* <li>
                                    <a href="https://www.facebook.com/QuomodoSoft"
                                    ><i className="fa-brands fa-youtube"></i
                                    ></a>
                                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6">
            <div className="widget-4">
              <h3 className="f-title">Liên kết</h3>
              <ul className="f-menu">
                {/* <li>
                  <a href="/about">Terms & Conditions</a>
                </li> */}
                <li>
                  <a href="/about">Về chúng tôi</a>
                </li>
                <li>
                  <a href="/payment-info">Thông tin thanh toán</a>
                </li>
                {/* <li>
                  <a href="/blogs">Business Support</a>
                </li> */}
                <li>
                  <a href="/contact">Liên hệ</a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-xl-3 col-lg-6">
            <div className="f-widget widget-3">
              <h3 className="f-title">Latest News</h3>
              <div className="recent-posts">
                {blogs &&
                  blogs.length > 0 &&
                  blogs.map((item) => (
                    <div className="rp-single">
                      <div className="thumb">
                        <img
                          src={`/assets/images/blog-post/${item.image}`}
                          alt={item.title}
                        />
                      </div>
                      <div className="content">
                        <h4>
                          <a href="/blog-details">{item.title}</a>
                        </h4>
                        <span>
                          <i className="fa-solid fa-calendar-days"></i>{item.date}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div> */}
          <div className="col-xl-3 col-lg-6">
            <div className="widget-4">
              <h3 className="f-title">Liên hệ</h3>
              <ul className="info-list">
                <li>
                  <a href={`tel:${getEnv("COMPANY_PHONE")}`}>
                    <i className="fa-solid fa-phone"></i>
                    {getEnv("COMPANY_PHONE")}
                  </a>
                </li>
                <li>
                  <a href={`mailto:${getEnv("COMPANY_EMAIL")}`}>
                    <i className="fa-solid fa-envelope"></i>
                    {getEnv("COMPANY_EMAIL")}
                  </a>
                </li>
                <li>
                  <a href={getEnv("COMPANY_MAP")}>
                    <i className="fa-solid fa-location-dot"></i>
                    {getEnv("COMPANY_ADDRESS")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-cr">
        <div className="container">
          <div className="footer-cr-container">
            <div className="p-left">
              <p>
              {(() => {
                var now = new Date();
                return `Copyright © ${now.getFullYear()}. All Rights Reserved.`
              })()}
              </p>
            </div>
            <div className="p-right">
              <ul className="cr-menu">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- [BEGIN] - Zalo OA --> */}
      <div
        class="zalo-chat-widget"
        data-oaid="2644163069038549863"
        data-welcome-message="Rất vui khi được hỗ trợ bạn!"
        data-autopopup="0"
        data-width="350"
        data-height="420"
      ></div>
      <script src="https://sp.zalo.me/plugins/sdk.js"></script>
      {/* <!-- [END] - Zalo OA --> */}
    </footer>
  );
}

export default Footer;
